<template>
  <div class="bg-white user-edit-wraper">

    <app-modal-confirm
      ref="modal_check_logout"
      :modal-header-title="$t('announcements.confirm_title')"
      @confirmed="logout"
      @cancel="cancelButtonLogoutModal"
    >
      <template v-slot:body>
        <p class="confirm-body">ログイン中のアカウントのパスワードを変更しました。再ログインしてください。</p>
      </template>
    </app-modal-confirm>

    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >
      <form class="form-horizontal" v-on:submit.prevent="passes(updatePassword)">
        <div class="row">
          <div class="col-12">
            <div class="card card-body">
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.id") }}</label>
                <label class="col-xl-1 col-lg-1 col-sm-1 m-t-8">{{ entry.id }}</label>
                <label class="col-xl-5 col-lg-6 col-sm-6 m-t-8">
                  <router-link
                  :to="{ name: ROUTES.ADMIN.ACCOUNT_EDIT, params: {id:idEditing} }"
                  style="text-decoration: underline"
                  >{{ $t("users.change_other_password") }}
                  </router-link></label>
              </div>
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.password") }} <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-6 col-sm-6">
                  <app-password
                    name="password"
                    type="password"
                    rules="required|password|min:6"
                    v-model="password"
                  ></app-password>
                </div>
              </div>

              <div class="form-group row m-b-1">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label"></label>
                <label class="col-xl-5 col-lg-6 col-sm-6 notifi-resetpass">{{ $t("auth.password note 1") }}</label>
              </div>
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label"></label>
                <label class="col-xl-5 col-lg-6 col-sm-6 notifi-resetpass">{{ $t("auth.password note 2") }}</label>
              </div>

              <div class="card-footer row justify-content-end gr-button">
                <button :disabled="!valid && validated" type="button" class="btn btn-default m-r-10" @click="confirmCancel">
                  {{ $t('common.cancel') }}
                </button>
                <button :disabled="!valid && validated" class="btn btn-primary m-r-10" type="submit">
                  {{  $t('common.save') }}
                </button>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { createHmac } from 'crypto';
export default {
  name: "FormPage",

  components: {
  },

  data() {
    const id = this.$route.params.id
    return {
      password: null,
      entry: {
        role: 1,
      },
      idEditing: id,
    };
  },
  mounted: function () {
    if (this.idEditing !== ':id') {
      this.$request.get(this.ENDPOINT.USERS_EDIT(this.idEditing)).then(res => {
        if (res.hasErrors()) {
          this.redirectBack()
        }
        this.entry = res.data.data
      })
    } else {
      this.redirectBack()
    }
  },
  methods: {
    confirmCancel()
    {
      this.redirectBack()
    },
    async updatePassword() {
      let res = null;
      let msg = "";
      // let params = _.cloneDeep(this.entry);
      // console.log(this.entry);
      let params = {
        'id':this.idEditing,
        'password':this.password,
        'admin_update_id' : this.$store.getters["auth/getUser"].id,
        'admin_update_name' : this.$store.getters["auth/getUser"].name
      };
      res = await this.$request.post(this.ENDPOINT.USERS_EDIT_PASSWORD, params);
      msg = this.$t("common.msg update ok")

      if (res.hasErrors()) {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
      } else {
        const user = this.$store.getters["auth/getUser"]
        if (this.idEditing == user.id) {
          // this.__noticeSuccess("ログイン中のアカウントのパスワードを変更しました。再ログインしてください。");
          this.$refs.modal_check_logout.show();
          // this.logoutAction();
        } else {
          this.__noticeSuccess(msg);
          this.redirectBack();
        }
      }
    },
    cancelButtonLogoutModal() {
      this.$refs.modal_check_logout.hide();
    },
    logout() {
      this.logoutAction();
    },
    redirectBack() {
      if (this.$store.state.setting.prevRouter.name === this.ROUTES.ADMIN.ACCOUNT_EDIT) {
        this.$router.push({name: this.ROUTES.ADMIN.ALL_USERS_LIST,
          query: {
            'filters.apartment_id.equal' : 'all',
            'filters.status_enabled.equal' : 'all'
          }
        });
      } else {
        this.$router.push({name: this.ROUTES.ADMIN.ANNOUNCEMENTS_LIST});
      }
    },
  },
};
</script>

